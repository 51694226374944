
import {
    Hooper,
    Slide,
    Navigation as HooperNavigation,
    Pagination as HooperPagination
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {

    props: ['newsGenerales'],
    components: {
        Hooper,
        Slide,
        HooperNavigation,
        HooperPagination
    },
    data() {
        for (const newsGenerale of this.newsGenerales) {
            newsGenerale.imageCarrousel = process.env.strapiBaseUri + newsGenerale.attributes.imageCarrousel?.data?.attributes?.url
        };
        return {
            hooperSettings: {
                itemsToShow: 1.20,
                centerMode: true,
                breakpoints: {
                    768: {
                        itemsToShow: 1.50
                    },
                    1024: {
                        centerMode: false,
                        itemsToShow: 3
                    },
                }
            }
        }
    },
}
