
import {
    Hooper,
    Slide,
    Navigation as HooperNavigation,
    Pagination as HooperPagination
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {

    props: ['homePagePremierEcrans'],
    components: {
        Hooper,
        Slide,
        HooperNavigation,
        HooperPagination
    },
    data() {
        for (const homePagePremierEcran of this.homePagePremierEcrans) {
            const imageDeFondUrl = process.env.strapiBaseUri + homePagePremierEcran.attributes.imageDeFond?.data?.attributes?.url;
            homePagePremierEcran.imageDeFondUrl = imageDeFondUrl
            const imageDeFondMobileUrl = process.env.strapiBaseUri + homePagePremierEcran.attributes.imageDeFondMobile?.data?.attributes?.url;
            homePagePremierEcran.imageDeFondMobileUrl = imageDeFondMobileUrl
        };
        return {
        }
    },
}
