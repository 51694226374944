

export default {
    data() {
        return {
            ref: null,
            openNavBar: false,
        };
    },
    methods: {
        scrollToCampus() {
            const element = document.getElementById("campus");
            element.scrollIntoView({ behavior: 'smooth' });
        },
        scrollToDiplomes() {
            const element = document.getElementById("diplomes");
            element.scrollIntoView({ behavior: 'smooth' });
        },
    }
}
