

import FirstScreenSliderHomePage from '~/components/FirstScreenSliderHomePage.vue';

export default {
    head() {
        return {
            title: `${this.infoGenerales?.attributes?.homePageTitle}`,
            meta: this.infoGenerales?.attributes?.homePageMeta != null && this.infoGenerales?.attributes?.homePageMeta != undefined ?
                JSON.parse(this.infoGenerales?.attributes?.homePageMeta?.replace('\n', '')) : null
        };

        //     title: `ICFA - Homepage`,
        //     meta: [
        //         {
        //             hid: 'og-title',
        //             property: 'og:title',
        //             content: 'ICFA - Formations par alternance - Bac+2 à Bac+3',
        //         },
        //         {
        //             hid: 'og-description',
        //             property: 'og:description',
        //             content: `Rejoignez l'ICFA pour des formations en alternance Bac+2 à Bac+3. Dans les Campus : St François, Lycée Nevers, La Merci Littoral, et St Joseph Pierre Rouge.`,
        //         }
        //     ]
        // };
    },

    components: { FirstScreenSliderHomePage },
    // name: 'Images',
    data() {
        let tabLevelChoice = [
            {
                visibility: false,
                label: 'BAC + 3',
                className: 'BAC3',
                id: [2, 6, 7, 8],
            },
            {
                visibility: false,
                label: 'BAC + 2',
                className: 'BAC2',
                id: [1],

            },
            {
                visibility: false,
                label: 'BAC',
                className: 'BAC',
                id: [5],

            },
            {
                visibility: false,
                label: 'INFRA - BAC',
                className: 'INFRA',
                id: [4, 3],

            },
        ];

        return {
            tabLevelChoice,
            curentLevel: null,
            curentDomaine: null,
            curentDiploma: null
        }

    },

    methods: {
        changeTabBac(wantedLevel) {
            this.curentLevel = wantedLevel;
            this.tabLevelChoice.map(v => {
                if (v.className == wantedLevel.className) v.visibility = true
                else v.visibility = false
            });
            this.findDomaineByLevel(wantedLevel.id);
            this.filteredDiplomas = []
            this.filteredInstances = []
            this.diplomasType = []
            this.domaines.map(domaine => domaine.selected = false)
            this.allDiplomas.map(diploma => diploma.selected = false)
        },
        findDomaineByLevel(id) {
            let domaineToShow = [];
            for (const diploma of this.allDiplomas) {
                if (id.includes(diploma.attributes.niveauDiplome?.data?.id)) {
                    if (domaineToShow.indexOf(diploma.attributes.domaine?.data?.id) === -1)
                        domaineToShow.push(diploma.attributes.domaine?.data?.id)
                }
            }
            for (const domaine of this.domaines) {
                if (domaineToShow.indexOf(domaine.id) === -1) {
                    domaine.visibility = false;
                } else {
                    domaine.visibility = true;
                }
            }

        },
        changeTabDomaine(domaine) {
            this.curentDomaine = domaine;
            this.domaines.map(dom => {
                if (dom.id == domaine.id) {
                    dom.selected = true;
                }
                else dom.selected = false;
            });
            this.filteredDiplomas = this.allDiplomas.filter(diploma => {
                // if(diploma.attributes.domaine?.data?.id == domaine.id &&  this.curentLevel.id.includes(diploma.attributes.niveauDiplome?.data?.id) )
                //     diploma.visibility = true 
                // else diploma.visibility = false;
                return diploma.attributes.domaine?.data?.id == domaine.id && this.curentLevel.id.includes(diploma.attributes.niveauDiplome?.data?.id)
            })
            this.buildDiplomaType()
            this.filteredInstances = []
            this.allDiplomas.map(diploma => diploma.selected = false)


        },
        buildDiplomaType() {
            this.diplomasType = []
            this.filteredDiplomas.map(diploma => {
                let diplomaTypeIndex = this.diplomasType.map(dt => dt.acronyme).indexOf(diploma.attributes.acronyme)
                if (diplomaTypeIndex == -1) {
                    let diplomaMap = new Map;
                    diplomaMap.set(diploma.campus, diploma)
                    this.diplomasType.push(
                        {
                            acronyme: diploma.attributes.acronyme,
                            diplomaMap,
                            title: diploma.attributes.title,
                            campus: [diploma.campus],
                            niveau: diploma.attributes.niveauDiplome.data.attributes.description,
                            attributes: {
                                couleurFormationRGB: diploma.attributes.couleurFormationRGB
                            },
                            selected: false
                        })
                } else {
                    this.diplomasType[diplomaTypeIndex].campus.push(diploma.campus)
                    this.diplomasType[diplomaTypeIndex].diplomaMap.set(diploma.campus, diploma)
                }
            })

        },
        changeTabDiploma(diploma) {
            this.curentDiploma = diploma
            this.diplomasType.map(dip => {
                if (dip.acronyme == diploma.acronyme) {
                    dip.selected = true;
                }
                else dip.selected = false;
            });
            this.filteredInstances = this.instances.filter(instance => {
                if (diploma.campus.includes(instance.attributes.prefix)) instance.visibility = true
                else instance.visibility = false;
                return diploma.campus.includes(instance.attributes.prefix)
            })
        },
        styles(instance) {
            let r, g, b;
            r = instance?.attributes?.couleurCampusCardRGB?.substring(0, 3);
            g = instance?.attributes?.couleurCampusCardRGB?.substring(3, 6);
            b = instance?.attributes?.couleurCampusCardRGB?.substring(6, 9);

            return {
                '--text-color': '#aaaaaa',
                '--text-color1r': Number(r),
                '--text-color1g': Number(g),
                '--text-color1b': Number(b),
            };
        },
        stylesGradDomaine(domaine) {
            let r, g, b;
            r = domaine?.attributes?.couleurDomaineRGB?.substring(0, 3);
            g = domaine?.attributes?.couleurDomaineRGB?.substring(3, 6);
            b = domaine?.attributes?.couleurDomaineRGB?.substring(6, 9);

            return {
                '--text-color': '#aaaaaa',
                '--text-color1r': Number(r) + 10,
                '--text-color1g': Number(g) - 55,
                '--text-color1b': Number(b) + 30,
                '--text-color2r': Number(r) + 10,
                '--text-color2g': Number(g) + 10,
                '--text-color2b': Number(b) + 25,
                '--text-color3r': Number(r) + 40,
                '--text-color3g': Number(g) - 50,
                '--text-color3b': Number(b) - 50,
                '--text-color4r': Number(r) - 30,
                '--text-color4g': Number(g) + 30,
                '--text-color4b': Number(b) + 55
            }
        },
        stylesGradDiploma(diplome) {
            let r, g, b;
            r = diplome?.attributes?.couleurFormationRGB?.substring(0, 3);
            g = diplome?.attributes?.couleurFormationRGB?.substring(3, 6);
            b = diplome?.attributes?.couleurFormationRGB?.substring(6, 9);

            return {
                '--text-color': '#aaaaaa',
                '--text-color1r': Number(r) + 10,
                '--text-color1g': Number(g) - 55,
                '--text-color1b': Number(b) + 30,
                '--text-color2r': Number(r) + 10,
                '--text-color2g': Number(g) + 10,
                '--text-color2b': Number(b) + 25,
                '--text-color3r': Number(r) + 40,
                '--text-color3g': Number(g) - 50,
                '--text-color3b': Number(b) - 50,
                '--text-color4r': Number(r) - 30,
                '--text-color4g': Number(g) + 30,
                '--text-color4b': Number(b) + 55
            }
        },
        stylesGradCampus(instance) {
            let r, g, b;
            r = instance?.attributes?.couleurCampusCardRGB?.substring(0, 3);
            g = instance?.attributes?.couleurCampusCardRGB?.substring(3, 6);
            b = instance?.attributes?.couleurCampusCardRGB?.substring(6, 9);

            return {
                '--text-color': '#aaaaaa',
                '--text-color1r': Number(r) + 10,
                '--text-color1g': Number(g) - 55,
                '--text-color1b': Number(b) + 30,
                '--text-color2r': Number(r) + 10,
                '--text-color2g': Number(g) + 10,
                '--text-color2b': Number(b) + 25,
                '--text-color3r': Number(r) + 40,
                '--text-color3g': Number(g) - 50,
                '--text-color3b': Number(b) - 50,
                '--text-color4r': Number(r) - 30,
                '--text-color4g': Number(g) + 30,
                '--text-color4b': Number(b) + 55
            }
        },

        scrollToCampusSmallButton() {
            const element = document.getElementById("campus");
            element.scrollIntoView({ behavior: 'smooth' });
        },
    },

    // created () {
    //     let self = this
    //     this.images = 

    //     setInterval(function(){ 
    //         self.mainImageSrc = self.combinedArray[Math.floor(Math.random()*self.combinedArray.length)].combinedArray;
    //     }, 2000);

    // },




    async asyncData({ params, $http, store }) {
        let instances = JSON.parse(JSON.stringify(store.state.instances));
        let infoGenerales = JSON.parse(JSON.stringify(store.state.infoGenerales));

        if (instances.length == 0) {
            let instances = await $http.$get(`${process.env.strapiBaseUri}/api/instances?populate=homePageCardImage`);
            instances = instances.data;
            instances.sort((a, b) => a.attributes.ordreHomePage - b.attributes.ordreHomePage)
            store.commit('setInstances', JSON.parse(JSON.stringify(instances)))

        }

        // let instances = store.state.instances;
        instances.map(instance => {
            instance.visibility = false;
            instance.diplomas = [];
        })

        let filteredInstances = [];

        const strapiBaseUri = process.env.strapiBaseUri

        let newsGenerales = await $http.$get(`${process.env.strapiBaseUri}/api/news-generales?populate=imageGenerale,imageCarrousel`);
        newsGenerales = newsGenerales.data;

        let allDiplomas = JSON.parse(JSON.stringify(store.state.diplomas));

        let collone1Diplomas = allDiplomas.filter((diploma) => {
            return diploma?.attributes.collone === 'Collone_1';
        })
        collone1Diplomas = collone1Diplomas.sort((d1, d2) => {
            return d1?.attributes.ordre - d2?.attributes.ordre
        })
        let collone2Diplomas = allDiplomas.filter((diploma) => {
            return diploma?.attributes.collone === 'Collone_2';
        })
        collone2Diplomas = collone2Diplomas.sort((d1, d2) => {
            return d1?.attributes.ordre - d2?.attributes.ordre
        })
        let collone3Diplomas = allDiplomas.filter((diploma) => {
            return diploma?.attributes.collone === 'Collone_3';
        })
        collone3Diplomas = collone3Diplomas.sort((d1, d2) => {
            return d1?.attributes.ordre - d2?.attributes.ordre
        })

        allDiplomas = collone1Diplomas.concat(collone2Diplomas).concat(collone3Diplomas);



        if (allDiplomas.length == 0) {
            for (const instance of instances) {
                const campusUrl = `${process.env.strapiBaseUri}/api/${instance.attributes.prefix}-diplomas?populate=domaine,niveauDiplome`;
                const campusDiplomaInfos = await $http.$get(campusUrl);
                campusDiplomaInfos.data.map(diplome => {
                    diplome.campus = instance.attributes.prefix;
                })
                allDiplomas = allDiplomas.concat(campusDiplomaInfos.data)
            }
            let collone1Diplomas = allDiplomas.filter((diploma) => {
                return diploma?.attributes.collone === 'Collone_1';
            })
            collone1Diplomas = collone1Diplomas.sort((d1, d2) => {
                return d1?.attributes.ordre - d2?.attributes.ordre
            })
            let collone2Diplomas = allDiplomas.filter((diploma) => {
                return diploma?.attributes.collone === 'Collone_2';
            })
            collone2Diplomas = collone2Diplomas.sort((d1, d2) => {
                return d1?.attributes.ordre - d2?.attributes.ordre
            })
            let collone3Diplomas = allDiplomas.filter((diploma) => {
                return diploma?.attributes.collone === 'Collone_3';
            })
            collone3Diplomas = collone3Diplomas.sort((d1, d2) => {
                return d1?.attributes.ordre - d2?.attributes.ordre
            })

            allDiplomas = collone1Diplomas.concat(collone2Diplomas).concat(collone3Diplomas);
            store.commit('setDiplomas', JSON.parse(JSON.stringify(allDiplomas)))
        }

        allDiplomas.map(diplome => {
            diplome.visibility = false;
            diplome.selected = false;
            instances.filter(instance => instance.attributes.prefix == diplome.campus)[0].diplomas.push(diplome)
        })


        let filteredDiplomas = [];
        let diplomasType = [];

        let homePagePremierEcrans = await $http.$get(`${process.env.strapiBaseUri}/api/hp-premier-ecrans?populate=imageDeFond,imageDeFondMobile`);
        homePagePremierEcrans = homePagePremierEcrans.data;



        let domaines = await $http.$get(`${process.env.strapiBaseUri}/api/domaines?`);
        domaines = domaines.data;
        domaines.map(domaine => {
            domaine.visibility = false;
            domaine.selected = false;
        });



        let i = 0;
        let displayedHomepageComponent = homePagePremierEcrans[i]


        return {
            instances, newsGenerales, strapiBaseUri, homePagePremierEcrans, domaines, infoGenerales,
            displayedHomepageComponent, allDiplomas, filteredDiplomas, filteredInstances, diplomasType
        }
    },

}
